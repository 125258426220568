import { FactoryProvider, ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ItkTokenManagementServiceDeprecated } from './token-management.service';
import { ClientAuthGuardDeprecated } from './client-auth.guard';
import { AdminClientAuthGuardDeprecated } from './admin-client-auth.guard';
import { ItkClientAuthInterceptorDeprecated } from './client-auth.interceptor';

/**
 * @deprecated
 */
@NgModule()
export class ItkClientAuthModuleDeprecated {
  static forRoot(
    provider: Omit<FactoryProvider, 'provide' | 'multi'>,
  ): ModuleWithProviders<ItkClientAuthModuleDeprecated> {
    return {
      ngModule: ItkClientAuthModuleDeprecated,
      providers: [
        {
          ...provider,
          provide: 'REFRESH_TOKEN',
        },
        ItkTokenManagementServiceDeprecated,
        ClientAuthGuardDeprecated,
        AdminClientAuthGuardDeprecated,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ItkClientAuthInterceptorDeprecated,
          multi: true,
        },
      ],
    };
  }
}
