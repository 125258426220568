/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PostResponseApiModel } from '../../models/post-response-api-model';
import { SimplePostRequestApiModel } from '../../models/simple-post-request-api-model';

export interface SimplePostRequest$Params {
  token: string;
  
    /**
     * This endpoint is used for integrations with vendors that do not operator on the Ping/Post model. This is a wrapper around the post endpoint with the intention of being simpler to integrate with. The auth token is passed as a URL parameter and apart from the `campaignId` requirement,
     * the input is completely dynamic.
     *
     * The POST body of this endpoint can contain any valid JSON data as long as there is a field `campaignId` at the root.
     */
    body: SimplePostRequestApiModel
}

export function simplePostRequest(http: HttpClient, rootUrl: string, params: SimplePostRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<PostResponseApiModel>> {
  const rb = new RequestBuilder(rootUrl, simplePostRequest.PATH, 'post');
  if (params) {
    rb.path('token', params.token, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PostResponseApiModel>;
    })
  );
}

simplePostRequest.PATH = '/ping-post/post/{token}';
