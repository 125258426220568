import { Injectable } from '@angular/core';
import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, map, Observable, switchMap } from 'rxjs';
import { ItkTokenManagementServiceDeprecated } from './token-management.service';

/**
 * @deprecated
 */
export const ITK_CLIENT_AUTH_DISABLED = new HttpContextToken<boolean>(
  () => false,
);

/**
 * @deprecated
 */
@Injectable()
export class ItkClientAuthInterceptorDeprecated implements HttpInterceptor {
  constructor(
    private readonly tokenManagement: ItkTokenManagementServiceDeprecated,
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.injectTokenIntoRequest(req, next);
  }

  injectTokenIntoRequest(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return from(this.tokenManagement.refreshTokenIfNeeded()).pipe(
      map(() => this.tokenManagement.getAccessToken()),
      map((token) =>
        req.context.get(ITK_CLIENT_AUTH_DISABLED) !== true
          ? { Authorization: `Bearer ${token}` }
          : undefined,
      ),
      map((headers) => req.clone({ setHeaders: { ...headers } })),
      switchMap((newReq) => next.handle(newReq)),
    );
  }
}
