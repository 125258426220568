/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getLeadDispositions } from '../fn/leads/get-lead-dispositions';
import { GetLeadDispositions$Params } from '../fn/leads/get-lead-dispositions';
import { LeadApiModel } from '../models/lead-api-model';
import { LeadDispositionsResponseApiModel } from '../models/lead-dispositions-response-api-model';
import { leadTypes } from '../fn/leads/lead-types';
import { LeadTypes$Params } from '../fn/leads/lead-types';
import { LeadTypesResponseApiModel } from '../models/lead-types-response-api-model';
import { requestReplacement } from '../fn/leads/request-replacement';
import { RequestReplacementApiModel } from '../models/request-replacement-api-model';
import { RequestReplacement$Params } from '../fn/leads/request-replacement';
import { retrieve } from '../fn/leads/retrieve';
import { Retrieve$Params } from '../fn/leads/retrieve';
import { SetDispositionResponseApiModel } from '../models/set-disposition-response-api-model';
import { setLeadDisposition } from '../fn/leads/set-lead-disposition';
import { SetLeadDisposition$Params } from '../fn/leads/set-lead-disposition';

@Injectable({ providedIn: 'root' })
export class ItkLeadLeadsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `leadsControllerLeadTypes()` */
  static readonly LeadsControllerLeadTypesPath = '/leads/types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leadTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  leadTypes$Response(params?: LeadTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<LeadTypesResponseApiModel>> {
    return leadTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leadTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  leadTypes(params?: LeadTypes$Params, context?: HttpContext): Observable<LeadTypesResponseApiModel> {
    return this.leadTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeadTypesResponseApiModel>): LeadTypesResponseApiModel => r.body)
    );
  }

  /** Path part for operation `leadsControllerGetLeadDispositions()` */
  static readonly LeadsControllerGetLeadDispositionsPath = '/leads/dispositions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeadDispositions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeadDispositions$Response(params?: GetLeadDispositions$Params, context?: HttpContext): Observable<StrictHttpResponse<LeadDispositionsResponseApiModel>> {
    return getLeadDispositions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeadDispositions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeadDispositions(params?: GetLeadDispositions$Params, context?: HttpContext): Observable<LeadDispositionsResponseApiModel> {
    return this.getLeadDispositions$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeadDispositionsResponseApiModel>): LeadDispositionsResponseApiModel => r.body)
    );
  }

  /** Path part for operation `leadsControllerSetLeadDisposition()` */
  static readonly LeadsControllerSetLeadDispositionPath = '/leads/disposition';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setLeadDisposition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setLeadDisposition$Response(params: SetLeadDisposition$Params, context?: HttpContext): Observable<StrictHttpResponse<SetDispositionResponseApiModel>> {
    return setLeadDisposition(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setLeadDisposition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setLeadDisposition(params: SetLeadDisposition$Params, context?: HttpContext): Observable<SetDispositionResponseApiModel> {
    return this.setLeadDisposition$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetDispositionResponseApiModel>): SetDispositionResponseApiModel => r.body)
    );
  }

  /** Path part for operation `leadsControllerRequestReplacement()` */
  static readonly LeadsControllerRequestReplacementPath = '/leads/replacement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestReplacement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestReplacement$Response(params: RequestReplacement$Params, context?: HttpContext): Observable<StrictHttpResponse<RequestReplacementApiModel>> {
    return requestReplacement(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestReplacement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestReplacement(params: RequestReplacement$Params, context?: HttpContext): Observable<RequestReplacementApiModel> {
    return this.requestReplacement$Response(params, context).pipe(
      map((r: StrictHttpResponse<RequestReplacementApiModel>): RequestReplacementApiModel => r.body)
    );
  }

  /** Path part for operation `leadsControllerRetrieve()` */
  static readonly LeadsControllerRetrievePath = '/leads/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieve$Response(params: Retrieve$Params, context?: HttpContext): Observable<StrictHttpResponse<LeadApiModel>> {
    return retrieve(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `retrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieve(params: Retrieve$Params, context?: HttpContext): Observable<LeadApiModel> {
    return this.retrieve$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeadApiModel>): LeadApiModel => r.body)
    );
  }

}
