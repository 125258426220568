/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountBalanceApiModel } from '../models/account-balance-api-model';
import { addFunds } from '../fn/admin/add-funds';
import { AddFunds$Params } from '../fn/admin/add-funds';
import { AdminGetAllCampaignsResponseApiModel } from '../models/admin-get-all-campaigns-response-api-model';
import { AdminGetLeadsForCampaignResponseApiModel } from '../models/admin-get-leads-for-campaign-response-api-model';
import { dissociateLead } from '../fn/admin/dissociate-lead';
import { DissociateLead$Params } from '../fn/admin/dissociate-lead';
import { getAllCampaigns } from '../fn/admin/get-all-campaigns';
import { GetAllCampaigns$Params } from '../fn/admin/get-all-campaigns';
import { getLeadsForCampaign } from '../fn/admin/get-leads-for-campaign';
import { GetLeadsForCampaign$Params } from '../fn/admin/get-leads-for-campaign';
import { getPendingReplacements } from '../fn/admin/get-pending-replacements';
import { GetPendingReplacements$Params } from '../fn/admin/get-pending-replacements';
import { getReplacements } from '../fn/admin/get-replacements';
import { GetReplacements$Params } from '../fn/admin/get-replacements';
import { LeadReplacementsResponseApiModel } from '../models/lead-replacements-response-api-model';
import { rerunSideEffects } from '../fn/admin/rerun-side-effects';
import { RerunSideEffects$Params } from '../fn/admin/rerun-side-effects';
import { setReplacementStatus } from '../fn/admin/set-replacement-status';
import { SetReplacementStatus$Params } from '../fn/admin/set-replacement-status';

@Injectable({ providedIn: 'root' })
export class ItkLeadAdminApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminControllerAddFunds()` */
  static readonly AdminControllerAddFundsPath = '/admin/funds/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFunds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFunds$Response(params: AddFunds$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountBalanceApiModel>> {
    return addFunds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addFunds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFunds(params: AddFunds$Params, context?: HttpContext): Observable<AccountBalanceApiModel> {
    return this.addFunds$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountBalanceApiModel>): AccountBalanceApiModel => r.body)
    );
  }

  /** Path part for operation `adminControllerGetAllCampaigns()` */
  static readonly AdminControllerGetAllCampaignsPath = '/admin/campaigns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCampaigns()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCampaigns$Response(params?: GetAllCampaigns$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminGetAllCampaignsResponseApiModel>> {
    return getAllCampaigns(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCampaigns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCampaigns(params?: GetAllCampaigns$Params, context?: HttpContext): Observable<AdminGetAllCampaignsResponseApiModel> {
    return this.getAllCampaigns$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminGetAllCampaignsResponseApiModel>): AdminGetAllCampaignsResponseApiModel => r.body)
    );
  }

  /** Path part for operation `adminControllerGetLeadsForCampaign()` */
  static readonly AdminControllerGetLeadsForCampaignPath = '/admin/campaigns/{campaignId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeadsForCampaign()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeadsForCampaign$Response(params: GetLeadsForCampaign$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminGetLeadsForCampaignResponseApiModel>> {
    return getLeadsForCampaign(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeadsForCampaign$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeadsForCampaign(params: GetLeadsForCampaign$Params, context?: HttpContext): Observable<AdminGetLeadsForCampaignResponseApiModel> {
    return this.getLeadsForCampaign$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminGetLeadsForCampaignResponseApiModel>): AdminGetLeadsForCampaignResponseApiModel => r.body)
    );
  }

  /** Path part for operation `adminControllerDissociateLead()` */
  static readonly AdminControllerDissociateLeadPath = '/admin/dissociate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dissociateLead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dissociateLead$Response(params: DissociateLead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return dissociateLead(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dissociateLead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dissociateLead(params: DissociateLead$Params, context?: HttpContext): Observable<void> {
    return this.dissociateLead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminControllerGetReplacements()` */
  static readonly AdminControllerGetReplacementsPath = '/admin/replacements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReplacements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReplacements$Response(params?: GetReplacements$Params, context?: HttpContext): Observable<StrictHttpResponse<LeadReplacementsResponseApiModel>> {
    return getReplacements(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReplacements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReplacements(params?: GetReplacements$Params, context?: HttpContext): Observable<LeadReplacementsResponseApiModel> {
    return this.getReplacements$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeadReplacementsResponseApiModel>): LeadReplacementsResponseApiModel => r.body)
    );
  }

  /** Path part for operation `adminControllerSetReplacementStatus()` */
  static readonly AdminControllerSetReplacementStatusPath = '/admin/replacements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setReplacementStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setReplacementStatus$Response(params: SetReplacementStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setReplacementStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setReplacementStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setReplacementStatus(params: SetReplacementStatus$Params, context?: HttpContext): Observable<void> {
    return this.setReplacementStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminControllerGetPendingReplacements()` */
  static readonly AdminControllerGetPendingReplacementsPath = '/admin/pending-replacements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPendingReplacements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPendingReplacements$Response(params?: GetPendingReplacements$Params, context?: HttpContext): Observable<StrictHttpResponse<LeadReplacementsResponseApiModel>> {
    return getPendingReplacements(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPendingReplacements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPendingReplacements(params?: GetPendingReplacements$Params, context?: HttpContext): Observable<LeadReplacementsResponseApiModel> {
    return this.getPendingReplacements$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeadReplacementsResponseApiModel>): LeadReplacementsResponseApiModel => r.body)
    );
  }

  /** Path part for operation `adminControllerRerunSideEffects()` */
  static readonly AdminControllerRerunSideEffectsPath = '/admin/rerun-side-effects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rerunSideEffects()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rerunSideEffects$Response(params: RerunSideEffects$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return rerunSideEffects(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rerunSideEffects$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rerunSideEffects(params: RerunSideEffects$Params, context?: HttpContext): Observable<void> {
    return this.rerunSideEffects$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
