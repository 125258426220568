/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CampaignLeadsResponseApiModel } from '../models/campaign-leads-response-api-model';
import { createBuyOrder } from '../fn/marketplace/create-buy-order';
import { CreateBuyOrder$Params } from '../fn/marketplace/create-buy-order';
import { getLeadsCsv } from '../fn/marketplace/get-leads-csv';
import { GetLeadsCsv$Params } from '../fn/marketplace/get-leads-csv';
import { getLeadsMap } from '../fn/marketplace/get-leads-map';
import { GetLeadsMap$Params } from '../fn/marketplace/get-leads-map';
import { getMarketplaceMetadata } from '../fn/marketplace/get-marketplace-metadata';
import { GetMarketplaceMetadata$Params } from '../fn/marketplace/get-marketplace-metadata';
import { getPurchase } from '../fn/marketplace/get-purchase';
import { GetPurchase$Params } from '../fn/marketplace/get-purchase';
import { getPurchases } from '../fn/marketplace/get-purchases';
import { GetPurchases$Params } from '../fn/marketplace/get-purchases';
import { MarketplaceLeadsBuyOrderRequestApiModel } from '../models/marketplace-leads-buy-order-request-api-model';
import { MarketplaceLeadsMapApiModel } from '../models/marketplace-leads-map-api-model';
import { MarketplaceMetadataApiModel } from '../models/marketplace-metadata-api-model';
import { MarketplaceSearchResponseApiModel } from '../models/marketplace-search-response-api-model';
import { PurchasedLeadsApiModel } from '../models/purchased-leads-api-model';
import { PurchasesResponseApiModel } from '../models/purchases-response-api-model';
import { searchMarketplace } from '../fn/marketplace/search-marketplace';
import { SearchMarketplace$Params } from '../fn/marketplace/search-marketplace';

@Injectable({ providedIn: 'root' })
export class ItkLeadMarketplaceApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `marketplaceControllerGetLeadsMap()` */
  static readonly MarketplaceControllerGetLeadsMapPath = '/marketplace/map';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeadsMap()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLeadsMap$Response(params: GetLeadsMap$Params, context?: HttpContext): Observable<StrictHttpResponse<MarketplaceLeadsMapApiModel>> {
    return getLeadsMap(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeadsMap$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLeadsMap(params: GetLeadsMap$Params, context?: HttpContext): Observable<MarketplaceLeadsMapApiModel> {
    return this.getLeadsMap$Response(params, context).pipe(
      map((r: StrictHttpResponse<MarketplaceLeadsMapApiModel>): MarketplaceLeadsMapApiModel => r.body)
    );
  }

  /** Path part for operation `marketplaceControllerSearchMarketplace()` */
  static readonly MarketplaceControllerSearchMarketplacePath = '/marketplace/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchMarketplace()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchMarketplace$Response(params: SearchMarketplace$Params, context?: HttpContext): Observable<StrictHttpResponse<MarketplaceSearchResponseApiModel>> {
    return searchMarketplace(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchMarketplace$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchMarketplace(params: SearchMarketplace$Params, context?: HttpContext): Observable<MarketplaceSearchResponseApiModel> {
    return this.searchMarketplace$Response(params, context).pipe(
      map((r: StrictHttpResponse<MarketplaceSearchResponseApiModel>): MarketplaceSearchResponseApiModel => r.body)
    );
  }

  /** Path part for operation `marketplaceControllerGetMarketplaceMetadata()` */
  static readonly MarketplaceControllerGetMarketplaceMetadataPath = '/marketplace/metadata';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketplaceMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketplaceMetadata$Response(params?: GetMarketplaceMetadata$Params, context?: HttpContext): Observable<StrictHttpResponse<MarketplaceMetadataApiModel>> {
    return getMarketplaceMetadata(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMarketplaceMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketplaceMetadata(params?: GetMarketplaceMetadata$Params, context?: HttpContext): Observable<MarketplaceMetadataApiModel> {
    return this.getMarketplaceMetadata$Response(params, context).pipe(
      map((r: StrictHttpResponse<MarketplaceMetadataApiModel>): MarketplaceMetadataApiModel => r.body)
    );
  }

  /** Path part for operation `marketplaceControllerCreateBuyOrder()` */
  static readonly MarketplaceControllerCreateBuyOrderPath = '/marketplace/purchase';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBuyOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBuyOrder$Response(params: CreateBuyOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<MarketplaceLeadsBuyOrderRequestApiModel>> {
    return createBuyOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBuyOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBuyOrder(params: CreateBuyOrder$Params, context?: HttpContext): Observable<MarketplaceLeadsBuyOrderRequestApiModel> {
    return this.createBuyOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<MarketplaceLeadsBuyOrderRequestApiModel>): MarketplaceLeadsBuyOrderRequestApiModel => r.body)
    );
  }

  /** Path part for operation `marketplaceControllerGetLeadsCsv()` */
  static readonly MarketplaceControllerGetLeadsCsvPath = '/marketplace/{id}/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeadsCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeadsCsv$Response(params: GetLeadsCsv$Params, context?: HttpContext): Observable<StrictHttpResponse<CampaignLeadsResponseApiModel>> {
    return getLeadsCsv(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLeadsCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeadsCsv(params: GetLeadsCsv$Params, context?: HttpContext): Observable<CampaignLeadsResponseApiModel> {
    return this.getLeadsCsv$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampaignLeadsResponseApiModel>): CampaignLeadsResponseApiModel => r.body)
    );
  }

  /** Path part for operation `marketplaceControllerGetPurchases()` */
  static readonly MarketplaceControllerGetPurchasesPath = '/marketplace/purchases';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPurchases()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPurchases$Response(params?: GetPurchases$Params, context?: HttpContext): Observable<StrictHttpResponse<PurchasesResponseApiModel>> {
    return getPurchases(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPurchases$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPurchases(params?: GetPurchases$Params, context?: HttpContext): Observable<PurchasesResponseApiModel> {
    return this.getPurchases$Response(params, context).pipe(
      map((r: StrictHttpResponse<PurchasesResponseApiModel>): PurchasesResponseApiModel => r.body)
    );
  }

  /** Path part for operation `marketplaceControllerGetPurchase()` */
  static readonly MarketplaceControllerGetPurchasePath = '/marketplace/purchase/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPurchase()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPurchase$Response(params: GetPurchase$Params, context?: HttpContext): Observable<StrictHttpResponse<PurchasedLeadsApiModel>> {
    return getPurchase(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPurchase$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPurchase(params: GetPurchase$Params, context?: HttpContext): Observable<PurchasedLeadsApiModel> {
    return this.getPurchase$Response(params, context).pipe(
      map((r: StrictHttpResponse<PurchasedLeadsApiModel>): PurchasedLeadsApiModel => r.body)
    );
  }

}
