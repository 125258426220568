/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createPing } from '../fn/ping-post/create-ping';
import { CreatePing$Params } from '../fn/ping-post/create-ping';
import { createPost } from '../fn/ping-post/create-post';
import { CreatePost$Params } from '../fn/ping-post/create-post';
import { dncRequest } from '../fn/ping-post/dnc-request';
import { DncRequest$Params } from '../fn/ping-post/dnc-request';
import { PingResponseApiModel } from '../models/ping-response-api-model';
import { PostResponseApiModel } from '../models/post-response-api-model';
import { simplePostRequest } from '../fn/ping-post/simple-post-request';
import { SimplePostRequest$Params } from '../fn/ping-post/simple-post-request';

@Injectable({ providedIn: 'root' })
export class ItkLeadPingPostApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pingPostControllerCreatePing()` */
  static readonly PingPostControllerCreatePingPath = '/ping-post/ping';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPing$Response(params: CreatePing$Params, context?: HttpContext): Observable<StrictHttpResponse<PingResponseApiModel>> {
    return createPing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPing(params: CreatePing$Params, context?: HttpContext): Observable<PingResponseApiModel> {
    return this.createPing$Response(params, context).pipe(
      map((r: StrictHttpResponse<PingResponseApiModel>): PingResponseApiModel => r.body)
    );
  }

  /** Path part for operation `pingPostControllerCreatePost()` */
  static readonly PingPostControllerCreatePostPath = '/ping-post/post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPost$Response(params: CreatePost$Params, context?: HttpContext): Observable<StrictHttpResponse<PostResponseApiModel>> {
    return createPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPost(params: CreatePost$Params, context?: HttpContext): Observable<PostResponseApiModel> {
    return this.createPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostResponseApiModel>): PostResponseApiModel => r.body)
    );
  }

  /** Path part for operation `pingPostControllerDncRequest()` */
  static readonly PingPostControllerDncRequestPath = '/ping-post/dnc/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dncRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dncRequest$Response(params: DncRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return dncRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dncRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dncRequest(params: DncRequest$Params, context?: HttpContext): Observable<void> {
    return this.dncRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `pingPostControllerSimplePostRequest()` */
  static readonly PingPostControllerSimplePostRequestPath = '/ping-post/post/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `simplePostRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  simplePostRequest$Response(params: SimplePostRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<PostResponseApiModel>> {
    return simplePostRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `simplePostRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  simplePostRequest(params: SimplePostRequest$Params, context?: HttpContext): Observable<PostResponseApiModel> {
    return this.simplePostRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostResponseApiModel>): PostResponseApiModel => r.body)
    );
  }

}
