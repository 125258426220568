/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PostRequestApiModel } from '../../models/post-request-api-model';
import { PostResponseApiModel } from '../../models/post-response-api-model';

export interface CreatePost$Params {

/**
 * Pass your token via the `Authorization` header prefixed with `Bearer` (e.g. `Authorization: Bearer xxxxxxx`)
 */
  Authorization?: string;
  
    /**
     * After a successful Ping, a `campaignId` and `leadId` are returned. These values are used to populate the `leadId` and `campaignId` fields of the Post body.
     *
     * If the `campaignId` was acquired by means other than the Ping endpoint (e.g. via a 3rd party API), then the `leadId` should match the `campaignId`.
     *
     * The `data` field does not enforce any kind of structure other than to be an Object. The fields in `data` shown below are just examples and not required.
     */
    body: PostRequestApiModel
}

export function createPost(http: HttpClient, rootUrl: string, params: CreatePost$Params, context?: HttpContext): Observable<StrictHttpResponse<PostResponseApiModel>> {
  const rb = new RequestBuilder(rootUrl, createPost.PATH, 'post');
  if (params) {
    rb.header('Authorization', params.Authorization, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PostResponseApiModel>;
    })
  );
}

createPost.PATH = '/ping-post/post';
