/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ItkLeadMarketplaceApi } from './services/itk-lead-marketplace-api';
import { ItkLeadManageFundsApi } from './services/itk-lead-manage-funds-api';
import { ItkLeadLeadsApi } from './services/itk-lead-leads-api';
import { ItkLeadPingPostApi } from './services/itk-lead-ping-post-api';
import { ItkLeadCampaignsApi } from './services/itk-lead-campaigns-api';
import { ItkLeadAdminApi } from './services/itk-lead-admin-api';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ItkLeadMarketplaceApi,
    ItkLeadManageFundsApi,
    ItkLeadLeadsApi,
    ItkLeadPingPostApi,
    ItkLeadCampaignsApi,
    ItkLeadAdminApi,
    ApiConfiguration
  ],
})
export class GeneratedApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<GeneratedApiModule> {
    return {
      ngModule: GeneratedApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: GeneratedApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('GeneratedApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
