import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { filter, map, merge, Observable, tap } from 'rxjs';
import { ClientAuthGuardDeprecated } from './client-auth.guard';
import { JwtTokenData, JwtTokenError } from './token-management.service';

/**
 * @deprecated
 */
@Injectable()
export class AdminClientAuthGuardDeprecated extends ClientAuthGuardDeprecated {
  override canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const tv = this.tokenManagement.refreshTokenIfNeeded();

    return merge(
      tv.pipe(
        filter((x): x is JwtTokenError => 'error' in x),
        map(() => false),
      ),
      tv.pipe(
        filter((x): x is JwtTokenData => !('error' in x)),
        map((claims) => {
          return (claims.roles ?? []).includes('admin');
        }),
      ),
    ).pipe(
      tap((x) => {
        if (!x) this.router.navigate(['/404/']);
      }),
    );
  }
}
