/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CampaignLeadsResponseApiModel } from '../models/campaign-leads-response-api-model';
import { CampaignsResponseApiModel } from '../models/campaigns-response-api-model';
import { CampaignStatusApiModel } from '../models/campaign-status-api-model';
import { create } from '../fn/campaigns/create';
import { Create$Params } from '../fn/campaigns/create';
import { CreateCampaignResponseApiModel } from '../models/create-campaign-response-api-model';
import { exportCampaignLeadsCsv } from '../fn/campaigns/export-campaign-leads-csv';
import { ExportCampaignLeadsCsv$Params } from '../fn/campaigns/export-campaign-leads-csv';
import { list } from '../fn/campaigns/list';
import { List$Params } from '../fn/campaigns/list';
import { passthroughCreate } from '../fn/campaigns/passthrough-create';
import { PassthroughCreate$Params } from '../fn/campaigns/passthrough-create';
import { retrieveCampaignLeads } from '../fn/campaigns/retrieve-campaign-leads';
import { RetrieveCampaignLeads$Params } from '../fn/campaigns/retrieve-campaign-leads';
import { retrieveCampaignStatus } from '../fn/campaigns/retrieve-campaign-status';
import { RetrieveCampaignStatus$Params } from '../fn/campaigns/retrieve-campaign-status';

@Injectable({ providedIn: 'root' })
export class ItkLeadCampaignsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `campaignControllerList()` */
  static readonly CampaignControllerListPath = '/campaigns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list()` instead.
   *
   * This method doesn't expect any request body.
   */
  list$Response(params?: List$Params, context?: HttpContext): Observable<StrictHttpResponse<CampaignsResponseApiModel>> {
    return list(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list(params?: List$Params, context?: HttpContext): Observable<CampaignsResponseApiModel> {
    return this.list$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampaignsResponseApiModel>): CampaignsResponseApiModel => r.body)
    );
  }

  /** Path part for operation `campaignControllerCreate()` */
  static readonly CampaignControllerCreatePath = '/campaigns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateCampaignResponseApiModel>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: Create$Params, context?: HttpContext): Observable<CreateCampaignResponseApiModel> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateCampaignResponseApiModel>): CreateCampaignResponseApiModel => r.body)
    );
  }

  /** Path part for operation `campaignControllerRetrieveCampaignStatus()` */
  static readonly CampaignControllerRetrieveCampaignStatusPath = '/campaigns/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveCampaignStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveCampaignStatus$Response(params: RetrieveCampaignStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<CampaignStatusApiModel>> {
    return retrieveCampaignStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `retrieveCampaignStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveCampaignStatus(params: RetrieveCampaignStatus$Params, context?: HttpContext): Observable<CampaignStatusApiModel> {
    return this.retrieveCampaignStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampaignStatusApiModel>): CampaignStatusApiModel => r.body)
    );
  }

  /** Path part for operation `campaignControllerRetrieveCampaignLeads()` */
  static readonly CampaignControllerRetrieveCampaignLeadsPath = '/campaigns/{id}/leads';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveCampaignLeads()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveCampaignLeads$Response(params: RetrieveCampaignLeads$Params, context?: HttpContext): Observable<StrictHttpResponse<CampaignLeadsResponseApiModel>> {
    return retrieveCampaignLeads(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `retrieveCampaignLeads$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveCampaignLeads(params: RetrieveCampaignLeads$Params, context?: HttpContext): Observable<CampaignLeadsResponseApiModel> {
    return this.retrieveCampaignLeads$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampaignLeadsResponseApiModel>): CampaignLeadsResponseApiModel => r.body)
    );
  }

  /** Path part for operation `campaignControllerExportCampaignLeadsCsv()` */
  static readonly CampaignControllerExportCampaignLeadsCsvPath = '/campaigns/{id}/leads/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCampaignLeadsCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCampaignLeadsCsv$Response(params: ExportCampaignLeadsCsv$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return exportCampaignLeadsCsv(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportCampaignLeadsCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCampaignLeadsCsv(params: ExportCampaignLeadsCsv$Params, context?: HttpContext): Observable<void> {
    return this.exportCampaignLeadsCsv$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `campaignControllerPassthroughCreate()` */
  static readonly CampaignControllerPassthroughCreatePath = '/campaigns/passthrough/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passthroughCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passthroughCreate$Response(params: PassthroughCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateCampaignResponseApiModel>> {
    return passthroughCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passthroughCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passthroughCreate(params: PassthroughCreate$Params, context?: HttpContext): Observable<CreateCampaignResponseApiModel> {
    return this.passthroughCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateCampaignResponseApiModel>): CreateCampaignResponseApiModel => r.body)
    );
  }

}
