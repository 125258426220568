/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountBalanceApiModel } from '../models/account-balance-api-model';
import { addFunds } from '../fn/manage-funds/add-funds';
import { AddFunds$Params } from '../fn/manage-funds/add-funds';
import { createPaymentIntent } from '../fn/manage-funds/create-payment-intent';
import { CreatePaymentIntent$Params } from '../fn/manage-funds/create-payment-intent';
import { listTransactions } from '../fn/manage-funds/list-transactions';
import { ListTransactions$Params } from '../fn/manage-funds/list-transactions';
import { PaymentIntentResponseApiModel } from '../models/payment-intent-response-api-model';
import { retrieveBalance } from '../fn/manage-funds/retrieve-balance';
import { RetrieveBalance$Params } from '../fn/manage-funds/retrieve-balance';
import { TransactionsResponseApiModel } from '../models/transactions-response-api-model';

@Injectable({ providedIn: 'root' })
export class ItkLeadManageFundsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `fundsControllerRetrieveBalance()` */
  static readonly FundsControllerRetrieveBalancePath = '/funds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveBalance()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveBalance$Response(params?: RetrieveBalance$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountBalanceApiModel>> {
    return retrieveBalance(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `retrieveBalance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveBalance(params?: RetrieveBalance$Params, context?: HttpContext): Observable<AccountBalanceApiModel> {
    return this.retrieveBalance$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountBalanceApiModel>): AccountBalanceApiModel => r.body)
    );
  }

  /** Path part for operation `fundsControllerAddFunds()` */
  static readonly FundsControllerAddFundsPath = '/funds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFunds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFunds$Response(params: AddFunds$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountBalanceApiModel>> {
    return addFunds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addFunds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFunds(params: AddFunds$Params, context?: HttpContext): Observable<AccountBalanceApiModel> {
    return this.addFunds$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountBalanceApiModel>): AccountBalanceApiModel => r.body)
    );
  }

  /** Path part for operation `fundsControllerListTransactions()` */
  static readonly FundsControllerListTransactionsPath = '/funds/transactions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTransactions$Response(params?: ListTransactions$Params, context?: HttpContext): Observable<StrictHttpResponse<TransactionsResponseApiModel>> {
    return listTransactions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTransactions(params?: ListTransactions$Params, context?: HttpContext): Observable<TransactionsResponseApiModel> {
    return this.listTransactions$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransactionsResponseApiModel>): TransactionsResponseApiModel => r.body)
    );
  }

  /** Path part for operation `fundsControllerCreatePaymentIntent()` */
  static readonly FundsControllerCreatePaymentIntentPath = '/funds/create-payment-intent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPaymentIntent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPaymentIntent$Response(params: CreatePaymentIntent$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentIntentResponseApiModel>> {
    return createPaymentIntent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPaymentIntent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPaymentIntent(params: CreatePaymentIntent$Params, context?: HttpContext): Observable<PaymentIntentResponseApiModel> {
    return this.createPaymentIntent$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentIntentResponseApiModel>): PaymentIntentResponseApiModel => r.body)
    );
  }

}
